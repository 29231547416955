/** @jsx jsx */
import { jsx, Flex, Grid } from 'theme-ui'
import { GatsbyImage } from 'gatsby-plugin-image'

const PartnerBanner = ({ bannerData }) => {
  return (
    <Flex
      sx={{
        flexDirection: 'column',
        width: '100%',
        marginTop: '110px',
        minHeight: '179px',
        backgroundColor: '#E56846',
        marginBottom: '57px',
      }}
    >
      <Grid
        sx={{
          marginTop: '32px',
          marginBottom: '28px',
          marginX: [0, null, 'auto'],
          display: 'grid',
          justifyItems: 'center',
          alignItems: 'center',
          maxWidth: ['none', null, 1150],
          gridTemplateColumns: [
            'repeat(auto-fit, minmax(70px, 1fr))',
            'repeat(auto-fit, minmax(125px, 1fr))',
          ],
        }}
      >
        {bannerData?.partners.map((partner) => {
          return (
            <a
              key={partner?.id}
              href={
                partner?.partnerUrl?.startsWith('http')
                  ? partner?.partnerUrl
                  : `https://${partner?.partnerUrl}`
              }
              target="_blank"
              rel="noopener noreferrer"
            >
              <GatsbyImage
                sx={{ maxWidth: '120px' }}
                image={partner?.partnerLogo?.gatsbyImageData}
                alt={partner?.partnerName}
              />
            </a>
          )
        })}
      </Grid>
    </Flex>
  )
}

export default PartnerBanner
