import React from 'react'

function WaterWave(props) {
  const title = props.title || 'water wave'

  return (
    <svg
      height="48"
      width="48"
      viewBox="0 0 48 48"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <title>{title}</title>
      <g>
        <path
          d="M46,24H44c-6.226,0-9.449-4.357-9.81-8.406-.224-2.526.721-5.693,4.125-6.3a1,1,0,0,0,.606-1.607c-.164-.206-4.084-5.055-9.734-5.628a11.613,11.613,0,0,0-8.8,3c-3.187,2.66-4.64,6.248-6.045,9.716C12.258,19.928,10.607,24,4,24H2a1,1,0,0,0-1,1V36a1,1,0,0,0,1,1H46a1,1,0,0,0,1-1V25A1,1,0,0,0,46,24Z"
          fill="#58bfca"
        />
        <path
          d="M46,46H2a1,1,0,0,1-1-1V35a1,1,0,0,1,1-1,9.179,9.179,0,0,0,6.766-3.079,1.035,1.035,0,0,1,.746-.321,1,1,0,0,1,.738.338,8.971,8.971,0,0,0,12.659.841,8.784,8.784,0,0,0,.841-.841,1.032,1.032,0,0,1,1.459-.041l.041.041a8.979,8.979,0,0,0,12.671.836c.129-.113.256-.231.379-.352a1,1,0,0,1,1.4,0A8.946,8.946,0,0,0,46,34a1,1,0,0,1,1,1V45A1,1,0,0,1,46,46Z"
          fill="#a0e6ee"
        />
      </g>
    </svg>
  )
}

export default WaterWave
